import './menu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncError } from 'react-router-dom'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import  {endpoint}  from '../../global/js/generic';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function menu (props) {
    const [menuStructure, setMenu] = useState([]);
    const Links = {
        "home": "/Home",
        "cv": "/About-me",
        "approach": "/Therapeutiki-proseggisi",
        "services" :"/Services"
    }
    const location = useLocation();
    const setActiveLi = () => {
        const elements = document.querySelectorAll('.menu-container > li');
        const locationNow = window.location.pathname;
        elements.forEach(e=>e.classList.remove('active'))
        const activeElements = [...elements].filter(e=>locationNow.includes(e.dataset.url));
        activeElements.forEach(e=>e.classList.add('active'))
      }
    useEffect(() => {
      setActiveLi();
      },[location]);
    useEffect(() => {
      setActiveLi();
      },[menuStructure]);
    useEffect(() => {
        fetchCategories();
      }, []);
      const fetchCategories = () => {
        return fetch(`${endpoint}/category?t=${Date.now()}`, { mode: 'cors', headers: { 'Content-Type': 'application/json'}})
        .then(response => response.json())
        .then(response => setMenu(response));
      }

    return( 
        <ul className='menu-container'>
            { !props.isResized && props.socialContainer }
            {   
                menuStructure.map( (x) => 
                 x.subcategories.length == 0 ? <li data-url={Links[x.type]}> <Link to={Links[x.type]}>{x.category_name}</Link>  </li> : 
                    <li className='hasSubcategories' data-url={Links[x.type]}> <Link to={Links[x.type]}>{x.category_name} <FontAwesomeIcon icon="fa-solid fa-chevron-down" /></Link>  
                        <ul className='subcategories-container'>
                            { !props.isMobile ? x.subcategories.map( (y, index)=> <li> <Link to={`${Links[x.type]}/id=${index}`} > { y.category_name } </Link> </li> ) 
                            : x.subcategories.map( (y, index)=> <li> <Link to={`${Links[x.type]}/id=${index}`} onClick={()=>props.openCloseMenu(false)}> { y.category_name } </Link> </li>)}
                        </ul>
                    </li>
                )
            }
            <ul className='mobile-social-container' >
                <li><a className='facebook' href="https://www.facebook.com/profile.php?id=100089457861729"><FontAwesomeIcon icon="fa-brands fa-facebook" /></a></li>
                <li><a className='linkedin' href="https://www.linkedin.com/in/maria-michaela-asimakopoulou-362251161/"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a></li>
                <li><a className='instagram' href="https://www.instagram.com/maria.michaela.asimakopoulou?igshid=YmMyMTA2M2Y'"><FontAwesomeIcon icon="fa-brands fa-instagram" /></a></li>
            </ul>
        </ul>
    )
}
export default menu;